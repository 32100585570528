import { CBreadcrumb, CBreadcrumbItem, CContainer } from "@coreui/react";

const Help = () => {
	return (
		<CContainer>
			<CBreadcrumb className="text-center mt-4 mb-4">
				<CBreadcrumbItem active>Help</CBreadcrumbItem>
			</CBreadcrumb>
			<div className="row">
				<div className="col-md-12">
					<div className="card">
						<div className="card-body">
							<p className="card-title fw-bold">
								For any question related to the Certificate Portal, please send your request to
							</p>
							<div className="row">
								<div className="col">
									<p className="card-text mt-3 " style={{ fontWeight: '500' }}>
										{'Email: '}
										<a href="mailto:itptma@lankhorsteuronete.com" target="itptma@lankhorsteuronete.com">
											itptma@lankhorsteuronete.com
										</a>
									</p>
									<p className="card-text " style={{ fontWeight: '400' }}>
										Please add the details of your request.
									</p>
									<p className="card-text col" style={{ fontWeight: '400' }}>
										Thanks
									</p>
									<p className="card-text">Lankhorst Euronete Portugal<br></br>IT Department </p>
								</div>
								<div className="col" style={{ marginTop: "18%" }}>
									<p className="card-text col" style={{ fontWeight: '400' }}>
										<strong>SmartDoc: </strong>v.1.0.5 Build 225 <br></br>Rua do Crasto,
										<br></br>194 4150-241 Porto
										<br></br>Tel. (351) 226 199 600
										<br></br>Fax.(351) 226 199 609
										<br></br>Copyright 2022 Pamafe IT
										<br></br>
										<strong>{'Email: '}</strong>
										<a href="info@pamafe.pt" target="info@pamafe.pt">
											info@pamafe.pt
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</CContainer>
	);
}

export default Help;
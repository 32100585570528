import { useMemo } from 'react';

function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = useMemo(() => {
        const options = [];
        preFilteredRows.forEach(row => {
            const value = row.values[id];
            const allReadyExists = options.some(option => option.value === value);
            if (value !== undefined && !allReadyExists) {
                if (row.original[`${id}Label`]) {
                    options.push({ value, label: row.original[`${id}Label`] });
                } else {
                    options.push({ value, label: row.values[id] });
                }
            }
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            className="form-select form-select-sm"
            value={filterValue}
            onClick={e => e.stopPropagation()}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map(({ value, label }, i) => (
                <option key={i} value={value}>
                    {label}
                </option>
            ))}
        </select>
    )
}

export default SelectColumnFilter;
import { CBreadcrumb, CBreadcrumbItem, CContainer } from "@coreui/react";
import { useEffect, useState } from "react";
import Media from 'react-media';
import { toast } from 'react-toastify';
import ArchiveService from "../services/archive.service";
import DeleteModal from "./DeleteModal";
import FilesLargeScreen from "./FilesLargeScreen";
import FilesSmallScreen from "./FilesSmallScreen";

const Files = ({ userProfile: { isSuperAdmin } }) => {
    const [data, setData] = useState({ rows: [], count: 0 });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteValue, setDeleteValue] = useState(null);

    const { location: { search } } = window;
    const archive = new URLSearchParams(search).get("archive") || false;
    const archiveName = new URLSearchParams(search).get("archiveName") || false;
    const archiveClient = new URLSearchParams(search).get("archiveClient") || false;

    useEffect(() => {
        ArchiveService.getFiles(archive)
            .then(files => setData(files))
            .catch(_ => toast.error("Could not load files"));
    }, [archive]);

    const clickRow = ({ original: { id: fileId } }) => {
        ArchiveService.getFile(fileId)
            .catch(_ => toast.error("Could not load file"));
    }

    const deleteItems = ({ original: { id, name, companyName, type } }) => {
        setDeleteValue({ 'id': id, 'name': name, 'type': type, 'companyName': companyName });
        setShowDeleteModal(true);
    }

    const deleteAction = (val) => {
        ArchiveService.deleteFile(val.id).then(() => {
            setShowDeleteModal(!showDeleteModal);
            toast.success("File deleted successfully");
            window.location.reload(false);
        }).catch(() => {
            toast.error("Error deleting File");
            setShowDeleteModal(!showDeleteModal);
        });

    }

    return (
        <CContainer>
            <DeleteModal
                {...{
                    showDeleteModal,
                    setShowDeleteModal,
                    deleteAction,
                    deleteValue,
                }}
                action={(val) => deleteAction(val)}
                messageHead={'file'} />
            <CBreadcrumb className="text-center mt-4 mb-4">
                <CBreadcrumbItem href="/">Certificates</CBreadcrumbItem>
                <CBreadcrumbItem active>
                    <Media queries={{ small: { maxWidth: 599 } }}>
                        {
                            matches =>
                                matches.small ?
                                    (
                                        <>
                                            {archiveName}
                                            {archiveClient ?
                                                `- ${archiveClient.length > 15 ? archiveClient.substring(0, 15) + "..." : archiveClient}`
                                                : ""
                                            }
                                        </>
                                    )
                                    : (
                                        <>
                                            Files from folder "{archiveName}"
                                            {archiveClient ? `- ${archiveClient}` : ""}
                                        </>
                                    )
                        }
                    </Media>

                </CBreadcrumbItem>
            </CBreadcrumb>
            <Media queries={{ small: { maxWidth: 599 } }}>
                {
                    matches =>
                        matches.small ?
                            (<FilesSmallScreen {...{ data, clickRow, isSuperAdmin, deleteItems }} />)
                            : (<FilesLargeScreen {...{ data, clickRow, isSuperAdmin, deleteItems }} />)
                }
            </Media>
        </CContainer>
    );
}


export default Files;

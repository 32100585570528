import { useState } from 'react';
import { CContainer, CRow, CCol, CCard, CCardBody, CButton } from '@coreui/react';
import { ToastContainer, toast } from 'react-toastify';
import UserService from '../services/user.service';

const ResetPassword = () => {

    const [newPassword, setNewPassword] = useState('');
    const [seeNewPassword, setSeeNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [seeConfirmPassword, setSeeConfirmPassword] = useState(false);

    const { location: { pathname } } = window;
    const token = pathname.split('reset/token=').pop();

    const resetPassword = () => {
        UserService.resetPassword(token, newPassword, confirmPassword)
            .then(() => {
                toast.success('Password updated successfully');
            })
            .catch(() => toast.error("Error updating password"));
    }

    return (
        <CContainer>
            <ToastContainer />
            <CRow className="justify-content-center  mt-3">
                <CCol xs="12" sm="10" md="7" lg="6" xl="5" xxl="4">
                    <CCard className='mb-3'>
                        <CCardBody>
                            <div className="mb-3">
                                <label className="form-label">New password</label>
                                <div className='d-flex'>
                                    <input
                                        type={seeNewPassword ? 'text' : 'password'}
                                        className="form-control me-1"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <CButton color="light" size="sm"
                                        onPointerDown={() => setSeeNewPassword(true)}
                                        onPointerUp={() => setSeeNewPassword(false)}
                                        onPointerLeave={() => setSeeNewPassword(false)}
                                    >
                                        <small>{seeNewPassword ? 'Hide' : 'Show'}</small>
                                    </CButton>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Confirm password</label>
                                <div className='d-flex'>
                                    <input
                                        type={seeConfirmPassword ? 'text' : 'password'}
                                        className="form-control me-1"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                    <CButton color="light" size="sm"
                                        onPointerDown={() => setSeeConfirmPassword(true)}
                                        onPointerUp={() => setSeeConfirmPassword(false)}
                                        onPointerLeave={() => setSeeConfirmPassword(false)}
                                    >
                                        <small>{seeConfirmPassword ? 'Hide' : 'Show'}</small>
                                    </CButton>
                                </div>
                            </div>
                            <CButton
                                color="primary"
                                className="w-100 mb-2"
                                onClick={resetPassword}
                            >
                                Save
                            </CButton>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    );
}
export default ResetPassword;

import { useState } from 'react';
import { CContainer } from '@coreui/react';
import { ToastContainer } from 'react-toastify';
import ForgotPasswordForm from '../components/ForgotPasswordForm.jsx';
import SignInForm from '../components/SignInForm.jsx';

const SignIn = () => {
    const [forgotPasswordMode, setForgotPasswordMode] = useState(false);

    return (
        <div className="vh-100 w-100 d-flex justify-content-center align-items-center"
            style={{ backgroundColor: 'var(--signInBackgroundColor)' }}>
            <CContainer>
                <ToastContainer />
                <main
                    className="p-5 mx-auto mb-2 border bg-white rounded shadow-sm"
                    style={{ maxWidth: '400px' }}
                >
                    {!forgotPasswordMode && <SignInForm setForgotPasswordMode={setForgotPasswordMode} />}
                    {forgotPasswordMode && <ForgotPasswordForm />}
                </main>
                {!forgotPasswordMode && (
                    <div className='text-center'>
                        <a className="h6 text-light" href="https://customer.lankhorstropes.com" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                            Old Certificates Portal (Up to Sep/2021)
                        </a>
                    </div>
                )}
            </CContainer>
        </div>
    );
}
export default SignIn;

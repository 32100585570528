import {
    CCardTitle,
    CCardText,
    CLink,
    CListGroup,
    CListGroupItem,
    CButton
} from '@coreui/react';
import UserRole from './UserRole';
import UserStatus from './UserStatus';

const ProfileInfo = ({
    userId,
    userProfile: {
        name,
        active,
        email,
        username,
        isSuperAdmin,
        companyName
    },
    editMode,
    setEditMode,
    editPasswordMode,
    setEditPasswordMode,
    editPermissionsMode,
    setEditPermissionsMode,
    deleteUserConfirmationMode,
    setDeleteUserConfirmationMode,
}) => {

    if (editMode || editPasswordMode || editPermissionsMode || deleteUserConfirmationMode) {
        return (<></>);
    }

    return (
        <>
            <CCardTitle className="text-center mb-4">
                {name}
            </CCardTitle>

            <CCardText>
                <CButton
                    color="light"
                    className="w-100 mb-2"
                    onClick={() => setEditMode(true)}
                >
                    Edit profile
                </CButton>
                <CButton
                    color="light"
                    className="w-100 mb-2"
                    onClick={() => setEditPasswordMode(true)}
                >
                    Change password
                </CButton>
                {userId && (
                    <CButton
                        color="light"
                        className="w-100 mb-2"
                        onClick={() => setEditPermissionsMode(true)}
                    >
                        Change permissions
                    </CButton>
                )}
                 {userId && isSuperAdmin !== 1 && (
                <CButton
                    color="danger"
                    className="w-100 mb-2"
                    onClick={() => setDeleteUserConfirmationMode(true)}
                >
                    Delete user
                </CButton>
                )}
            </CCardText>

            <CListGroup flush>
                <CListGroupItem className='d-flex'>
                    <strong className='me-1'>Username:</strong>
                    <span>{username ? username : 'N/A'}</span>
                </CListGroupItem>
                <CListGroupItem className='d-flex'>
                    <strong className='me-1'>Email:</strong>
                    {email && (
                        <CLink href={`mailto:${email}`} style={{ textDecoration: 'none' }}>
                            {email}
                        </CLink>
                    )}
                    {!email && (<span>No email provided</span>)}
                </CListGroupItem>
                <CListGroupItem className='d-flex'>
                    <strong className='me-1'>Company:</strong>
                    <span>{companyName ? companyName : 'No company'}</span>
                </CListGroupItem>
                <CListGroupItem>
                    <strong>Role: </strong>
                    <UserRole isSuperAdmin={isSuperAdmin} />
                </CListGroupItem>
                {userId && (
                    <CListGroupItem>
                        <strong>Status: </strong>
                        <UserStatus active={active} />
                    </CListGroupItem>
                )}
            </CListGroup>
        </>
    );
}

export default ProfileInfo;
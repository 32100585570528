import CIcon from "@coreui/icons-react";
import { cilFolder } from '@coreui/icons';

const Folder = ({ name }) => (
    <span key={name}>
        <CIcon icon={cilFolder} />
        {` ${name}`}
    </span>
);

export default Folder;
import Media from 'react-media';
import HeaderLargeScreen from './HeaderLargeScreen';
import HeaderSmallScreen from './HeaderSmallScreen';

const Header = (props) => (
    <Media queries={{ small: { maxWidth: 599 } }}>
        {
            matches =>
                matches.small ?
                    (<HeaderSmallScreen {...props} />)
                    : (<HeaderLargeScreen {...props} />)
        }
    </Media>
);

export default Header;
import { useEffect, useState } from 'react';
import { CButton, CListGroup, CListGroupItem, CCardTitle, CRow, CCol } from '@coreui/react';
import CIcon from "@coreui/icons-react";
import { cilTrash } from '@coreui/icons';
import { toast } from 'react-toastify';
import Select from 'react-select'
import PermissionService from '../services/permission.service';
import Folder from './Folder';

const ProfilePermissionsEdit = ({
    userId,
    userProfile: {
        name
    },
    editPermissionsMode,
    resetProfile,
}) => {

    const [authorizedFolders, setAuthorizedFolders] = useState([]);
    const [unathorizedFolders, setUnathorizedFolders] = useState([]);
    const [selectValue, setSelectValue] = useState(null);

    useEffect(() => {
        PermissionService.getUserAuthorizedFolders(userId)
            .then(permissions => setAuthorizedFolders(permissions))
            .catch(() => toast.error("Could not load user permissions"))

        PermissionService.getUserUnauthorizedFolders(userId)
            .then(permissions => setUnathorizedFolders(permissions))
            .catch(() => toast.error("Could not load user permissions"))

    }, [userId]);

    const addFolderToAuthorized = (folder) => {
        setAuthorizedFolders([...authorizedFolders, folder]);
        setUnathorizedFolders(unathorizedFolders.filter(f => f.id !== folder.id));
    }

    const removeFolderFromAuthorized = (folder) => {
        setAuthorizedFolders(authorizedFolders.filter(f => f.id !== folder.id));
        setUnathorizedFolders([...unathorizedFolders, folder]);
    }

    const addAllFoldersToAuthorized = () => {
        setAuthorizedFolders([...authorizedFolders, ...unathorizedFolders]);
        setUnathorizedFolders([]);
    }

    const removeAllFoldersFromAuthorized = () => {
        setAuthorizedFolders([]);
        setUnathorizedFolders([...unathorizedFolders, ...authorizedFolders]);
    }

    const onChangeSelect = (selectedOption) => {
        if (selectedOption) {
            const { value: id, label: name } = selectedOption;
            setSelectValue(name);
            addFolderToAuthorized({ id, name });
            setSelectValue(null);
        }
    }

    const setUserAuthorizedFolders = () => {
        const folders = authorizedFolders.map(f => f.id);
        PermissionService.setUserAuthorizedFolders(userId, folders)
            .then(() => toast.success("Permissions updated"))
            .catch(() => toast.error("Could not update permissions"))
            .finally(() => resetProfile());
    }


    if (!editPermissionsMode) {
        return (<></>);
    }

    const selectOptions = unathorizedFolders.map(folder => ({ value: folder.id, label: folder.name }));

    return (
        <>
            <CCardTitle className="text-center mb-4">
                {name}
            </CCardTitle>

            <CRow className='mb-3'>
                <Select
                    options={selectOptions}
                    value={selectValue}
                    onChange={onChangeSelect}
                    placeholder="Add specific folder"
                    isSearchable={true}
                />
            </CRow>

            <CRow className='mb-3'>
                <CCol>
                    <CButton color="light" className="w-100" onClick={addAllFoldersToAuthorized}>
                        Add all
                    </CButton>
                </CCol>
                <CCol>
                    <CButton color="light" className="w-100" onClick={removeAllFoldersFromAuthorized}>
                        Remove all
                    </CButton>
                </CCol>
            </CRow>

            <div className="mt-4" style={{
                maxHeight: '28vh',
                overflowY: "auto"
            }} >
                <CListGroup>
                    {authorizedFolders.map(folder => (
                        <CListGroupItem key={folder.name} className="d-flex justify-content-between align-items-center">
                            <Folder name={folder.name} />
                            <CButton color="light" onClick={() => removeFolderFromAuthorized(folder)}>
                                <CIcon icon={cilTrash} />
                            </CButton>
                        </CListGroupItem>
                    ))
                        .reverse()}
                </CListGroup>
            </div>

            <CButton
                color="primary"
                className="w-100 mb-2"
                onClick={setUserAuthorizedFolders}
            >
                Save
            </CButton>
            <CButton
                color="light"
                className="w-100"
                onClick={resetProfile}
            >
                Cancel
            </CButton>
        </>
    );
}

export default ProfilePermissionsEdit;
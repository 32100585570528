import { CButton, CCardTitle } from "@coreui/react";
import { toast } from "react-toastify";
import UserService from "../services/user.service";

const DeleteUserConfirmation = ({
    deleteUserConfirmationMode,
    resetProfile,
    userProfile: { id, name },
}) => {
    const deleteUser = () => {
        UserService.deleteUser(id)
            .then(() => {
                toast.success("User deleted successfully");
                resetProfile();
                window.location.href = "/users";
            })
            .catch(() => toast.error("Error deleting user"));
    };

    if (!deleteUserConfirmationMode) {
        return <></>;
    }

    return (
        <>
            <div className="row-fluid">
                <div className="span12 text-center">
                    Are you sure you want to delete
                </div>
            </div>
            <CCardTitle className="text-center mb-4">{name} ?</CCardTitle>
            <CButton color="primary" className="w-100 mb-2" onClick={deleteUser}>
                OK
            </CButton>
            <CButton color="light" className="w-100" onClick={resetProfile}>
                Cancel
            </CButton>
        </>
    );
};

export default DeleteUserConfirmation;

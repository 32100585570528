import { useEffect, useState } from 'react';
import AuthService from '../services/auth.service.js';
import SignInBottomLogo from './SignInBottomLogo.jsx';
import SignInTopLogo from './SignInTopLogo.jsx';

const SignInForm = ({ setForgotPasswordMode }) => {
    const [checkedAllreadySignIn, setCheckedAllreadySignIn] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        AuthService.refreshToken()
            .then(_ => window.location.href = '/')
            .catch(_ => setCheckedAllreadySignIn(true));
    }, []);

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            setError('');
            await AuthService.login(username, password);
            setLoading(false);
        } catch ({ response: { data: { message } } }) {
            setLoading(false);
            setError(message);
        }
    }

    if (!checkedAllreadySignIn) {
        return (<></>);
    }

    return (
        <form onSubmit={handleSubmit}>

            <SignInTopLogo />

            <h1 className="h3 mb-3 fw-normal text-center">Sign in</h1>

            <div className="form-floating mb-2">
                <input type="text" className="form-control" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                <label htmlFor="floatingInput">Username</label>
            </div>
            <div className="form-floating mb-3">
                <input type="password" className="form-control" id="password" onChange={(e) => setPassword(e.target.value)} value={password} />
                <label htmlFor="floatingPassword">Password</label>
            </div>

            <button className="w-100 btn btn-lg btn-primary" type="submit" disabled={loading}>
                {loading ? 'Loading...' : 'Sign In'}
            </button>

            {error && <div className="alert alert-danger mt-5 mb-3">{error}</div>}

            <div className="h6 mt-3">
                <a style={{ textDecoration: 'none', cursor: 'pointer' }}
                    onClick={() => setForgotPasswordMode(true)}>
                    Forgot password?
                </a>
            </div>

            <SignInBottomLogo />

        </form>
    );
}
export default SignInForm;

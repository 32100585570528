import { useEffect, useState } from "react";
import { CBreadcrumb, CBreadcrumbItem, CContainer, CRow, CCol, CButton } from '@coreui/react';
import Media from 'react-media';
import UserService from "../services/user.service";
import UsersSmallScreen from "./UsersSmallScreen";
import UsersLargeScreen from "./UsersLargeScreen";

const Users = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        UserService.getAllUsers()
            .then(users => setUsers(users))
    }, []);

    const clickRow = ({ original: { id } }) => {
        window.location.href = `users/profile?user=${id}`
    }

    return (
        <CContainer>
            <CRow className='align-items-center'>
                <CCol>
                    <CBreadcrumb className="text-center mt-4 mb-4">
                        <CBreadcrumbItem active>Users</CBreadcrumbItem>
                    </CBreadcrumb>
                </CCol>
                <CCol style={{ textAlign: 'right' }}>
                    <CButton
                        color="primary"
                        className="px-4"
                        onClick={() => window.location.href = 'users/add'} >
                        Add User
                    </CButton>
                </CCol>
            </CRow>
            <Media queries={{ small: { maxWidth: 599 } }}>
                {
                    matches =>
                        matches.small ?
                            (<UsersSmallScreen {...{ users, clickRow }} />)
                            : (<UsersLargeScreen {...{ users, clickRow }} />)
                }
            </Media>
        </CContainer>
    );
}

export default Users;
import { cilFile, cilFolder } from '@coreui/icons';
import CIcon from "@coreui/icons-react";
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";

const DeleteModal = ({ showDeleteModal, setShowDeleteModal, deleteValue, action, messageHead }) => {
    return (
        <>
            <CModal visible={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
                <CModalHeader>
                    <CModalTitle>{"Are you sure you want to delete the " + messageHead + '?'}  </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <p>
                        <strong className="text-danger"><CIcon icon={messageHead === 'file' ? cilFile : cilFolder} /> { messageHead +' :'} </strong>  {deleteValue?.name}
                    </p>
                    <p>
                        <strong>Company Name:</strong>  {deleteValue?.companyName}
                    </p>
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => setShowDeleteModal(false)}>
                        <span className="text-white"> Close</span>
                    </CButton>
                    <CButton color="danger" onClick={() => action(deleteValue)}>Delete</CButton>
                </CModalFooter>
            </CModal>
        </>
    )
}
export default DeleteModal;
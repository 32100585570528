import { getEnv } from "./env";

export const properties = {
    mainBackgroundColor: getEnv('REACT_APP_MAIN_BACKGROUND_COLOR'),
    signInBackgroundColor: getEnv('REACT_APP_SIGN_IN_BACKGROUND_COLOR'),
    primaryColor: getEnv('REACT_APP_PRIMARY_COLOR'),
}

export const setProperties = () => {
    for (let key in properties) {
        document.documentElement.style.setProperty(`--${key}`, properties[key]);
    }
}
import axios from "axios";
import AuthService from "./auth.service";

const { REACT_APP_API_BASE_URL: apiBaseUrl } = process.env;

const instance = axios.create({
    baseURL: `${apiBaseUrl}/`,
    withCredentials: true,
    timeout: 15000 
});

instance.interceptors.response.use(
    (response) => {
        return response;
    }
    , async (error) => {
        try {
            const { response: { status, config } } = error;
            const { url } = config;
            const isUnauthorized = status === 401;
            const isAuthRoute = url.includes("auth");
            const isLogoutRoute = url.includes("log-out");
            if (isUnauthorized) {
                if (!isAuthRoute || isLogoutRoute) {
                    await AuthService.refreshToken();
                    return instance.request(config);
                }
                const location = window.location.toString();
                if (!location.includes("signin")) {
                    localStorage.setItem("redirectTo", location);
                    window.location.href = "/signin";
                }
            }
            return Promise.reject(error);
        }
        catch (err) {
            console.log({ error });
            window.location.href = "/signin";
            return Promise.reject(err);
        }
    }
);

export default instance;

function DefaultColumnFilter({
    column: {
        filterValue,
        Header,
        /*preFilteredRows,*/
        setFilter
    },
}) {

    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            onClick={e => e.stopPropagation()}
            placeholder={`Search ${Header}`}
            className="form-control form-control-sm"
        />
    )
}

export default DefaultColumnFilter;
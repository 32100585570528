import { useState } from 'react';
import UserService from '../services/user.service.js';
import SignInBottomLogo from './SignInBottomLogo.jsx';
import SignInTopLogo from './SignInTopLogo.jsx';

const ForgotPasswordForm = () => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            setError('');
            await UserService.requestResetPassword(email, username);
            setLoading(false);
            setSuccess(true);
        } catch ({ response: { data: { message } } }) {
            alert(message);
            setLoading(false);
            setError(message);
        }
    }


    return (
        <form onSubmit={handleSubmit}>

            <SignInTopLogo />

            <h1 className="h3 mb-3 fw-normal text-center">
                Reset Password
            </h1>

            {success && (
                <>
                    <div className="alert alert-success mt-5 mb-3">
                        An email has been sent to you with a link to reset your password.
                    </div>

                    <div className="h6 mt-3">
                        <button className="w-100 btn btn-lg btn-primary" onClick={() => window.location.href = '/'}>
                            Sign In
                        </button>
                    </div>

                </>
            )}

            {!success && (
                <>
                    <div className="form-floating mb-2">
                        <input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <label htmlFor="floatingEmail">Email</label>
                    </div>

                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                        <label htmlFor="floatingInput">Username</label>
                    </div>


                    <button className="w-100 btn btn-lg btn-primary mb-3" type="submit" disabled={loading}>
                        {loading ? 'Loading...' : 'Reset Password'}
                    </button>

                    <button className="w-100 btn btn-lg btn-secondary" type="button" onClick={() => window.location.href = '/'}>
                        Cancel
                    </button>

                    {error && <div className="alert alert-danger mt-5 mb-3">{error}</div>}
                </>
            )}

            <SignInBottomLogo />

        </form>
    );
}
export default ForgotPasswordForm;

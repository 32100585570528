import { useState } from 'react';
import { useAsyncDebounce } from 'react-table';

function GlobalFilter({
    /*preGlobalFilteredRows,*/
    globalFilter,
    setGlobalFilter,
    columns,
    rows
}) {

    const placeholder = columns
        .filter(({ show = true }) => show)
        .map(({ Header }) => (Header)).join(', ') + ' and more';
    //const count = preGlobalFilteredRows.length
    const count = rows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <>
            <div className="input-group flex-nowrap">
                <span className="input-group-text">
                    Search
                </span>
                <input
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={placeholder}
                    className="form-control form-control-sm"
                />
            </div>
            <div className="text-muted text-center mt-2">
                <small>
                    {`${count} records...`}
                </small>
            </div>
        </>
    )
}

export default GlobalFilter;
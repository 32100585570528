import { CBreadcrumb, CBreadcrumbItem, CContainer } from "@coreui/react";
import { useEffect, useState } from "react";
import Media from 'react-media';
import { toast } from 'react-toastify';
import ArchiveService from "../services/archive.service";
import CertificatesLargeScreen from "./CertificatesLargeScreen";
import CertificatesSmallScreen from "./CertificatesSmallScreen";
import DeleteModal from './DeleteModal';

const Certificates = ({ userProfile: { isSuperAdmin } }) => {
    const [data, setData] = useState({ rows: [], count: 0 });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteValue, setDeleteValue] = useState(null);


    useEffect(() => {
        ArchiveService.getFolders()
            .then(folders => setData(folders))
            .catch(_ => toast.error("Could not load folders"));
    }, []);

    const clickRow = ({ original: { id, name, companyName } }) => {
        window.location.href = `files?archive=${id}&archiveName=${name}&archiveClient=${companyName}`;
    }

    const deleteItems = ({ original: { id, name, companyName, type } }) => {
        setDeleteValue({ 'id': id, 'name': name, 'type': type, 'companyName': companyName });
        setShowDeleteModal(true);
    }

    const deleteAction = () => {

        ArchiveService.deleteFolder(deleteValue.id).then(() => {
            setShowDeleteModal(!showDeleteModal);
            toast.success("Folder deleted successfully");
            window.location.reload(false);
        }).catch(() => {
            toast.error("Error deleting Folder");
            setShowDeleteModal(!showDeleteModal);
        });

    }

    return (
        <CContainer>
            <DeleteModal  {...{
                showDeleteModal,
                setShowDeleteModal,
                deleteAction,
                deleteValue,
            }}
                action={(val) => deleteAction(val)}
                messageHead={'Folder'}/>
            <CBreadcrumb className="text-center mt-4 mb-4">
                <CBreadcrumbItem active>Certificates</CBreadcrumbItem>
            </CBreadcrumb>
            <Media queries={{ small: { maxWidth: 599 } }}>
                {
                    matches =>
                        matches.small ?
                            (<CertificatesSmallScreen {...{ data, clickRow, isSuperAdmin, deleteItems }} />)
                            : (<CertificatesLargeScreen {...{ data, clickRow, isSuperAdmin, deleteItems }} />)
                }
            </Media>
        </CContainer>
    );
}

export default Certificates;
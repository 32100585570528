import {
    CContainer,
    CHeader,
    CHeaderBrand,
    CSidebar,
    CSidebarNav,
    CNavGroup,
    CNavTitle,
    CButton
} from "@coreui/react";
import {
    cilHamburgerMenu,
    cilX
} from '@coreui/icons';
import CIcon from "@coreui/icons-react";
import { useState } from "react";
import { navItens, userNavItens } from "./HeaderNavItens";
import Logo from "./Logo";
import HeaderSmallScreenNavIten from "./HeaderSmallScreenNavIten";

const HeaderSmallScreen = ({ userProfile }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
    const closeSidebar = () => { if (sidebarOpen) setSidebarOpen(false) };
    const username = userProfile ? userProfile.username : '';
    const isSuperAdmin = userProfile ? userProfile.isSuperAdmin : false;

    return (
        <>
            <CHeader>
                <CContainer onClick={closeSidebar}>
                    <CButton color="dark" onClick={toggleSidebar}>
                        <CIcon icon={sidebarOpen ? cilX : cilHamburgerMenu} />
                    </CButton>
                    <CHeaderBrand href="/">
                        <Logo height="30" />
                    </CHeaderBrand>
                </CContainer>
            </CHeader>
            <CSidebar
                className={`w-100 ${sidebarOpen ? "show d-flex" : "hide d-none"}`}
                style={{ top: "64px" }}
            >
                <CSidebarNav>
                    <CNavTitle>Navigation</CNavTitle>
                    {navItens.map(item => {
                        const { hide: isToHide = false } = item;
                        const showAdmin = !isToHide && item.onlySuperAdmin && isSuperAdmin && isSuperAdmin === 1 ? true : false;
                        const showAllUsers = !isToHide && !item.onlySuperAdmin;
                        return (
                            <span key={item.name}>
                                {showAdmin && (<HeaderSmallScreenNavIten {...item} />)}
                                {showAllUsers && (<HeaderSmallScreenNavIten {...item} />)}
                            </span>
                        )
                    })}
                    <CNavGroup toggler={username}>
                        {userNavItens.map(item => ((<HeaderSmallScreenNavIten {...item} key={item.name} />)))}
                    </CNavGroup>
                </CSidebarNav>
            </CSidebar>
        </>
    );
}

export default HeaderSmallScreen;
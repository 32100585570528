import { useState } from 'react';
import { CContainer, CRow, CCol, CCard, CCardBody, CBreadcrumb, CBreadcrumbItem, CButton, CFormSwitch } from '@coreui/react';
import { toast } from 'react-toastify';
import UserService from '../services/user.service';

const AddUser = ({ userProfile }) => {

    if (userProfile.isSuperAdmin !== 1) {
        window.location.href = '/forbidden';
    }

    const defaultNewData = { active: 1, name: '', email: '', username: '', password: '', companyName: '', companyCode: '', isSuperAdmin: 0 };
    const [newData, setNewData] = useState(defaultNewData);
    const [formErrors, setFormErrors] = useState({});
    const inputs = [
        { id: 'name', label: 'Name', value: newData.name, required: true },
        { id: 'email', type: 'email', label: 'Email', value: newData.email, required: true },
        { id: 'username', label: 'Username', value: newData.username, required: true },
        { id: 'password', type: 'password', label: 'Password', value: newData.password, required: true },
        { id: 'companyName', label: 'Company Name', value: newData.companyName, required: true },
        { id: 'companyCode', label: 'Company Code', value: newData.companyCode, required: true },
        { id: 'active', type: 'checkbox', label: 'Status', value: newData.active, required: false },
        { id: 'isSuperAdmin', type: 'checkbox', label: 'Is Super Admin', value: newData.isSuperAdmin, required: false },
    ];

    const createUser = () => {
        const isValid = validateForm();
        if (isValid) {
            UserService.createUser(newData)
                .then(({ id }) => {
                    setNewData(defaultNewData);
                    window.location.href = `/users/profile?user=${id}`;
                })
                .catch(({ response: { data: { message } } }) =>
                    toast.error("Error creating user - " + message));
        }
    }

    const validateForm = () => {
        let valid;
        let errors = {};

        inputs.forEach(({ id, required }) => {
            const { [id]: value } = newData;
            if (required && !value) {
                errors[id] = `${id} is required`;
            } else {
                switch (id) {
                    case 'email':
                        valid = value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
                        errors[id] = valid ? '' : 'Invalid email address';
                        break;
                    case 'name':
                        valid = value.length >= 6;
                        errors[id] = valid ? '' : 'Name must be at least 6 characters';
                        break;
                    case 'username':
                        valid = value.length >= 6;
                        errors[id] = valid ? '' : 'Username must be at least 6 characters';
                        break;
                    case 'companyName':
                        valid = value.length >= 6;
                        errors[id] = valid ? '' : 'Company Name must be at least 6 characters';
                        break;
                    case 'companyCode':
                        valid = value.length >= 3;
                        errors[id] = valid ? '' : 'Company Code must be at least 3 characters';
                        break;
                    case 'password':
                        valid = value.length >= 6;
                        errors[id] = valid ? '' : 'Password must be at least 6 characters';
                        break;
                    default:
                        break;
                }
            }
        });
        setFormErrors({ ...errors });
        return Object.values(errors).every(v => v === '');
    }

    const handleChange = ({ target: { id, value } }) => {
        setNewData({ ...newData, [id]: value });
        setFormErrors({ ...formErrors, [id]: '' });
    }

    const handleToggleChange = ({ target: { id, value } }) => {
        const boolValue = value === 'true' ? true : false;
        setNewData({ ...newData, [id]: !boolValue });
    }

    const cancelEdit = () => {
        window.history.back();
    }

    return (
        <CContainer>
            <CBreadcrumb className="text-center mt-4 mb-4">
                <CBreadcrumbItem href="/users">Users</CBreadcrumbItem>
                <CBreadcrumbItem active>Add Users</CBreadcrumbItem>
            </CBreadcrumb>
            <CRow className="justify-content-center">
                <CCol xs="12" sm="10" md="7" lg="6" xl="5" xxl="4">
                    <CCard className='mb-3' key={JSON.stringify(userProfile)}>
                        <CCardBody>
                            {inputs.map(({ id, type = 'text', label, value }) => (
                                <div className="mb-3" key={id}>
                                    <label className="form-label">{label}</label>
                                    {type === 'checkbox' && (
                                        <CFormSwitch
                                            defaultChecked={value === 1 ? true : false}
                                            value={value === 1 ? true : false}
                                            onChange={handleToggleChange}
                                            id={id}
                                        />
                                    )}
                                    {type !== 'checkbox' && (
                                        <input
                                            type={type}
                                            className={`form-control ${formErrors[id] ? 'is-invalid' : ''}`}
                                            value={value}
                                            onChange={handleChange}
                                            id={id}
                                        />
                                    )}
                                    <div className="invalid-feedback">{formErrors[id]}</div>
                                </div>
                            ))}
                            <CButton color="primary" className="w-100 mb-2" onClick={createUser}>
                                Add User
                            </CButton>
                            <CButton color="light" className="w-100" onClick={cancelEdit}>
                                Cancel
                            </CButton>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    );
}

export default AddUser;
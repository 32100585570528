import { CNavItem } from "@coreui/react";
import CIcon from "@coreui/icons-react";

const HeaderSmallScreenNavIten = ({ name, url, icon, onClick }) => (
    <CNavItem href={url} onClick={onClick}>
        <CIcon customClassName="nav-icon" icon={icon} />
        {name}
    </CNavItem>
);

export default HeaderSmallScreenNavIten;
import { useMemo } from "react";
import Table from "./Table/Table";
import DefaultCell from "./Table/DefaultCell";

const CertificatesLargeScreen = ({ data: { rows, count }, clickRow, isSuperAdmin, deleteItems }) => {

    const columns = useMemo(
        () => [
            {
                Header: 'Folder',
                accessor: 'name',
                Cell: ({ value }) => (<DefaultCell icon="folder" value={value} />),
            },
            {
                Header: 'Client',
                accessor: 'companyName',
                Cell: ({ value }) => (<DefaultCell value={value} />),
            },
            {
                Header: 'Created At',
                accessor: 'createdAt',
                Cell: ({ value }) => (<DefaultCell value={value} />),
            },

        ],
        []
    )
    if (isSuperAdmin === 1) {
        columns.push({
            Header: 'Actions',
            show:false,
            Cell: ({ value }) => (<DefaultCell value={'delete'} />),
        })
    }
    return (<Table columns={columns} data={rows} clickRow={clickRow} deleteItems={deleteItems} />)
}

export default CertificatesLargeScreen;
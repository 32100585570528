import React from "react";
import { useTable, useFilters, useSortBy, useGlobalFilter } from "react-table";
import DefaultColumnFilter from "./DefaultColumnFilter";
import GlobalFilter from "./GlobalFilter";
import CIcon from "@coreui/icons-react";
import { cilTrash } from '@coreui/icons';
function Table({ columns, data, clickRow = false, deleteItems=false }) {
    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({ Filter: DefaultColumnFilter }),
        []
    );
    const initialState = {
        hiddenColumns: columns
            .filter(({ show = true }) => !show)
            .map(({ accessor }) => accessor),
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState,
            defaultColumn,
            filterTypes,
        },
        useFilters,
        useGlobalFilter,
        useSortBy
    );

    return (
        <>
            <div className="table-responsive shadow-sm" style={{ maxHeight: "70vh" }}>
                <table
                    className="table table-bordered table-hover mb-0"
                    {...getTableProps()}
                >
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps()}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={
                                            column.isSorted
                                                ? column.isSortedDesc
                                                    ? "sort-desc"
                                                    : "sort-asc"
                                                : ""
                                        }
                                    >
                                        {column.accessor ? column.render("Header") : null}
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <span> &#x25BC;</span>
                                            ) : (
                                                <span> &#x25B2;</span>
                                            )
                                        ) : null}
                                        {column.accessor ? (
                                            <div>
                                                {column.accessor && column.canFilter
                                                    ? column.render("Filter")
                                                    : null}
                                            </div>
                                        ) : (
                                            <div className="d-flex align-items-center justify-content-center mb-3">
                                                {column.render("Header")}
                                            </div>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                        <tr>
                            <th
                                colSpan={visibleColumns.length}
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                    columns={columns}
                                    rows={rows}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                  
                                    {row.cells.map((cell) => {

                                        return (
                                            <td
                                                style={{
                                                    width: "20vw!important",
                                                    wordBreak: "break-all",
                                                }}
                                                {...cell.getCellProps()}

                                                onDoubleClick={(e) => {
                                                    e.preventDefault();
                                                    if (clickRow && cell.column.Header !== 'Actions') {
                                                        clickRow(row);
                                                    }

                                                }}
                                                
                                            >
                                                {cell.column.Header !== 'Actions' ? <pre>{cell.render("Cell")}</pre> : <div style={{ alignItems: 'center',justifyContent:'center' }} className="d-flex align-items-middle justify-content-center  text-danger "><CIcon style={{alignSelf:'center'}} icon={cilTrash} size="xl" onClick={(e) => {
                                                    e.preventDefault();
                                                    if (clickRow && cell.column.Header === 'Actions') {
                                                        deleteItems(row)
                                                    }
                                                }} /></div>}
                                             
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Table;

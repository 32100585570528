import { CNavItem, CNavLink } from "@coreui/react";

const HeaderLargeScreenNavIten = ({ name, url }) => (
    <CNavItem>
        <CNavLink href={url}>
            {name}
        </CNavLink>
    </CNavItem>
);

export default HeaderLargeScreenNavIten;
import { useEffect, useState } from 'react';
import { CContainer, CRow, CCol, CCard, CCardBody, CAlert, CBreadcrumb, CBreadcrumbItem } from '@coreui/react';
import { useLocation } from 'react-router-dom';
import Gravatar from 'react-gravatar';
import { toast } from 'react-toastify';
import UserService from '../services/user.service';
import ProfileInfo from './ProfileInfo';
import ProfileInfoEdit from './ProfileInfoEdit';
import ProfilePasswordEdit from './ProfilePasswordEdit';
import Loading from './Loading';
import ProfilePermissionsEdit from './ProfilePermissionsEdit';
import DeleteUserConfirmation from "./DeleteUserConfirmation";

const UserProfile = () => {
    const { search } = useLocation();
    const [userId, setUserId] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [editPasswordMode, setEditPasswordMode] = useState(false);
    const [editPermissionsMode, setEditPermissionsMode] = useState(false);
    const [deleteUserConfirmationMode, setDeleteUserConfirmationMode] = useState(false);

    useEffect(() => {
        setUserId(new URLSearchParams(search).get("user") || false);
        getUserProfile();
    }, [search, userId]);

    const getUserProfile = () => {
        if (userId) {
            UserService.getUserProfile(userId)
                .then(userProfile => setUserProfile(userProfile))
                .catch(() => toast.error("Could not load user profile"))
                .finally(() => setLoading(false));
        }
    }

    const resetProfile = () => {
        getUserProfile();
        setEditMode(false);
        setEditPasswordMode(false);
        setEditPermissionsMode(false);
        setDeleteUserConfirmationMode(false);
    }

    const propsToPass = {
        userId,
        userProfile,
        editMode,
        setEditMode,
        editPasswordMode,
        setEditPasswordMode,
        resetProfile,
        editPermissionsMode,
        setEditPermissionsMode,
        deleteUserConfirmationMode,
        setDeleteUserConfirmationMode
    };

    return (
        <CContainer>
            <CBreadcrumb className="text-center mt-4 mb-4">
                <CBreadcrumbItem href="/users">Users</CBreadcrumbItem>
                <CBreadcrumbItem active>User Profile</CBreadcrumbItem>
            </CBreadcrumb>

            {loading && (<Loading />)}
            <CRow className="justify-content-center">
                <CCol xs="12" sm="10" md="7" lg="6" xl="5" xxl="4">
                    {!loading && !userProfile && (
                        <CAlert color="info" className="text-center">
                            User profile not found!
                        </CAlert>
                    )}
                    {!loading && userProfile && (
                        <CCard className='mb-3' key={JSON.stringify(userProfile)}>
                            <Gravatar email={userProfile.email || 'example@gmail.com'} size={120} className="rounded-circle mx-auto mt-4" />
                            <CCardBody>
                                <ProfileInfo {...propsToPass} />
                                <ProfileInfoEdit {...propsToPass} />
                                <ProfilePasswordEdit {...propsToPass} />
                                <ProfilePermissionsEdit {...propsToPass} />
                                <DeleteUserConfirmation {...propsToPass} />
                            </CCardBody>
                        </CCard>
                    )}
                </CCol>
            </CRow>
        </CContainer>
    );
}

export default UserProfile;
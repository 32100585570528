import React from "react";
import { CImage } from "@coreui/react";

const SignInTopLogo = () => (
    <CImage
        src="/signInTopLogo.local.png"
        onError={(e) => { e.target.src = "/signInTopLogo.png" }}
        className="w-100 mb-4"
        alt=""
    />
);

export default SignInTopLogo;
import API from "./api";

const getFolders = (
    query = {
        limit: 10000,
        offset: 0
    }) => {

    const queryString = Object.keys(query)
        .map(key => `${key}=${query[key]}`)
        .join('&');

    return API.get(`archive/folders?${queryString}`)
        .then((response) => response.data)
};

const getFiles = (
    archiveId,
    query = {
        limit: 10000,
        offset: 0
    }
) => {

    const queryString = Object.keys(query)
        .map(key => `${key}=${query[key]}`)
        .join('&');

    return API.get(`archive/${archiveId}/file?${queryString}`)
        .then((response) => response.data);
};

const getFile = (fileId) => API
    .get(`archive/${fileId}/file/open`, { responseType: "blob" })
    .then((response) => response.data)
    .then((file) => {
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    });

const deleteFolder = (folderId) => API.delete(`archive/${folderId}`); 

const deleteFile = (fileId) => API.delete(`archive/file/${fileId}`);

const ArchiveService = {
    getFolders,
    getFiles,
    getFile,
    deleteFolder,
    deleteFile
}

export default ArchiveService; 
import { useState } from 'react';
import { CButton, CFormSwitch } from '@coreui/react';
import { toast } from 'react-toastify';
import UserService from '../services/user.service';

const ProfileInfoEdit = ({
    userId = false,
    userProfile,
    editMode,
    editPasswordMode,
    resetProfile
}) => {

    const { active, name, email, username, companyName } = userProfile;
    const defaultNewData = { active, name, email, username, companyName };
    const [newData, setNewData] = useState(defaultNewData);
    const [formErrors, setFormErrors] = useState({});
    const inputs = [
        { id: 'name', label: 'Name', value: newData.name, required: true },
        { id: 'email', type: 'email', label: 'Email', value: newData.email, required: true },
        { id: 'username', label: 'Username', value: newData.username, required: true },
        { id: 'companyName', label: 'Company Name', value: newData.companyName, required: true },
    ];

    if (userId) {
        inputs.push({ id: 'active', type: 'checkbox', label: 'Status', value: newData.active, required: false });
    }

    const updateProfile = () => {
        const isValid = validateForm();
        if (isValid) {
            UserService.updateUserProfile(newData, userId)
                .then(() => {
                    toast.success('Profile updated successfully');
                    resetProfile();
                    setNewData(defaultNewData);
                })
                .catch(() => toast.error("Error updating profile"));
        }
    }

    const validateForm = () => {
        let valid;
        let errors = {};

        inputs.forEach(({ id, required }) => {
            const { [id]: value } = newData;
            if (required && !value) {
                errors[id] = `${id} is required`;
            } else {
                switch (id) {
                    case 'email':
                        valid = value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
                        errors[id] = valid ? '' : 'Invalid email address';
                        break;
                    case 'name':
                        valid = value.length >= 6;
                        errors[id] = valid ? '' : 'Name must be at least 6 characters';
                        break;
                    case 'username':
                        valid = value.length >= 6;
                        errors[id] = valid ? '' : 'Username must be at least 6 characters';
                        break;
                    case 'companyName':
                        valid = value.length >= 6;
                        errors[id] = valid ? '' : 'Company Name must be at least 6 characters';
                        break;
                    default:
                        break;
                }
            }
        });
        setFormErrors({ ...errors });
        return Object.values(errors).every(v => v === '');
    }

    const handleChange = ({ target: { id, value } }) => {
        setNewData({ ...newData, [id]: value });
        setFormErrors({ ...formErrors, [id]: '' });
    }

    const handleToggleChange = ({ target: { id, value } }) => {
        const boolValue = value === 'true' ? true : false;
        setNewData({ ...newData, [id]: !boolValue });
    }

    const cancelEdit = () => {
        resetProfile();
        setNewData(defaultNewData);
        setFormErrors({});
    }

    if (!editMode || editPasswordMode) {
        return (<></>);
    }

    return (
        <>
            {inputs.map(({ id, type = 'text', label, value }) => (
                <div className="mb-3" key={id}>
                    <label className="form-label">{label}</label>
                    {type === 'checkbox' && (
                        <CFormSwitch
                            defaultChecked={value === 1 ? true : false}
                            value={value === 1 ? true : false}
                            onChange={handleToggleChange}
                            id={id}
                        />
                    )}
                    {type !== 'checkbox' && (
                        <input
                            type={type}
                            className={`form-control ${formErrors[id] ? 'is-invalid' : ''}`}
                            value={value}
                            onChange={handleChange}
                            id={id}
                        />
                    )}
                    <div className="invalid-feedback">{formErrors[id]}</div>
                </div>
            ))}
            <CButton color="primary" className="w-100 mb-2" onClick={updateProfile}>
                Save
            </CButton>
            <CButton color="light" className="w-100" onClick={cancelEdit}>
                Cancel
            </CButton>
        </>
    );
}

export default ProfileInfoEdit;
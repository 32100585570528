import API from "./api";

const refreshToken = () => API.get("auth/refresh-token")
    .then(() => console.log("Token refreshed"))

const login = (username, password) => API
    .post("auth/login", { username, password })
    .then((response) => response.data)
    .then(() => {
        const redirectTo = localStorage.getItem("redirectTo") || "/";
        localStorage.removeItem("redirectTo");
        window.location.href = redirectTo;
    })

const logout = () => API.get("auth/log-out")
    .then(() => { window.location.href = '/'; });

const AuthService = {
    refreshToken,
    login,
    logout
}

export default AuthService;
import { useState } from 'react';
import { CButton } from '@coreui/react';
import { toast } from 'react-toastify';
import UserService from '../services/user.service';

const ProfilePasswordEdit = ({
    userId = false,
    editPasswordMode,
    resetProfile,
}) => {

    const [currPassword, setCurrPassword] = useState('');
    const [seeCurrPassword, setSeeCurrPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [seeNewPassword, setSeeNewPassword] = useState(false);

    const updatePassword = () => {
        UserService.updatePassword(currPassword, newPassword, userId)
            .then(() => {
                toast.success('Password updated successfully');
                resetProfile();
            })
            .catch(() => toast.error("Error updating password"));
    }

    if (!editPasswordMode) {
        return (<></>);
    }

    return (
        <>
            {!userId && (

                <div className="mb-3">
                    <label className="form-label">Current password</label>
                    <div className='d-flex'>
                        <input
                            type={seeCurrPassword ? 'text' : 'password'}
                            className="form-control  me-1"
                            value={currPassword}
                            onChange={(e) => setCurrPassword(e.target.value)}
                        />
                        <CButton color="light" size="sm"
                            onPointerDown={() => setSeeCurrPassword(true)}
                            onPointerUp={() => setSeeCurrPassword(false)}
                            onPointerLeave={() => setSeeCurrPassword(false)}
                        >
                            <small>{seeCurrPassword ? 'Hide' : 'Show'}</small>
                        </CButton>
                    </div>
                </div>
            )}
            <div className="mb-3">
                <label className="form-label">New password</label>
                <div className='d-flex'>
                    <input
                        type={seeNewPassword ? 'text' : 'password'}
                        className="form-control me-1"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <CButton color="light" size="sm"
                        onPointerDown={() => setSeeNewPassword(true)}
                        onPointerUp={() => setSeeNewPassword(false)}
                        onPointerLeave={() => setSeeNewPassword(false)}
                    >
                        <small>{seeNewPassword ? 'Hide' : 'Show'}</small>
                    </CButton>
                </div>
            </div>
            <CButton
                color="primary"
                className="w-100 mb-2"
                onClick={updatePassword}
            >
                Save
            </CButton>
            <CButton
                color="light"
                className="w-100"
                onClick={resetProfile}
            >
                Cancel
            </CButton>
        </>
    );
}

export default ProfilePasswordEdit;
import moment from 'moment';

const Moment = ({ value }) => {
    const isMoment = value &&
        !value.includes(" ") &&
        !value.includes("_") &&
        value.includes("Z") &&
        moment(value).isValid();

    return (
        <>
            {isMoment && moment(value).format('DD/MM/YYYY')}
        </>
    );
}

export default Moment;
import {
    cilFolderOpen,
    cilRecycle,
    cilInfo,
    cilShieldAlt,
    cilPuzzle,
} from '@coreui/icons';
import AuthService from '../services/auth.service';

export const navItens = [
    {
        name: 'Certificates',
        icon: cilFolderOpen,
        url: '/'
    },
    {
        name: 'Recycle',
        icon: cilRecycle,
        url: '/recycle',
        hide: true
    },
    {
        name: 'Users',
        icon: cilShieldAlt,
        url: '/users',
        onlySuperAdmin: true
    },
    {
        name: 'Help',
        icon: cilInfo,
        url: '/help'
    },
];

export const userNavItens = [
    {
        name: 'Profile',
        icon: cilPuzzle,
        url: '/profile'
    },
    {
        name: 'Logout',
        icon: cilPuzzle,
        url: '/',
        onClick: (event) => {
            event.preventDefault();
            AuthService.logout()
        }
    }
];

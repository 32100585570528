export const getEnv = (envName) => {
    const envValue = process.env[envName];
    if (!envValue || envValue === '') {
        throw new Error(`${envName} is not defined`);
    }
    if (envValue === 'true') {
        return true;
    }
    if (envValue === 'false') {
        return false;
    }
    return envValue;
}
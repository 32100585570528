import React from "react";
import { CImage } from "@coreui/react";

const SignInBottomLogo = () => (
    <div className="d-flex justify-content-center align-items-center">
        <CImage
            src="/signInBottomLogo.local.png"
            onError={(e) => { e.target.src = "/signInBottomLogo.png" }}
            className="mt-5 mb-3"
            alt=""
            height="50"
        />
    </div>
);

export default SignInBottomLogo;
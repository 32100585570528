import { useMemo } from "react";
import Table from "./Table/Table";
import Moment from "./Table/Moment";

const CertificatesSmallScreen = ({ data: { rows, count }, clickRow, isSuperAdmin, deleteItems }) => {

    const columns = useMemo(
        () => [
            {
                Header: 'Certificate Information',
                Cell: ({ cell: { row: { original: { name, companyName, createdAt } } } }) => (
                    (
                        <div>
                            <strong>Folder:</strong> {name}
                            <br />
                            <strong>Client:</strong> {companyName || 'N/A'}
                            <br />
                            <strong>Created At: </strong>
                            <Moment value={createdAt} />
                        </div>
                    )
                ),
            },
            {
                Header: 'Folder',
                accessor: 'name',
                show: false,
            },
            {
                Header: 'Client',
                accessor: 'companyName',
                show: false,
            },
            {
                Header: 'Created At',
                accessor: 'createdAt',
                show: false,
            },
         
        ],
        []
    )
    if (isSuperAdmin === 1) {
        columns.push({ Header: 'Actions', show: false })
    }

    return (<Table columns={columns} data={rows} clickRow={clickRow} deleteItems={deleteItems} />)
}

export default CertificatesSmallScreen;
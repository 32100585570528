import { useMemo } from "react";
import Table from "./Table/Table";
import Moment from "./Table/Moment";

const FilesSmallScreen = ({ data: { rows, count }, clickRow, isSuperAdmin, deleteItems }) => {

    const columns = useMemo(
        () => [
            {
                Header: 'File Information',
                Cell: ({ cell: { row: { original: { filename, createdAt, description, clientReference, salesOrder } } } }) => (
                    <div>
                        <strong>File:</strong> {filename}
                        <br />
                        <strong>Date: </strong>
                        <Moment value={createdAt} />
                        <br />
                        <strong>Description:</strong> {description || 'N/A'}
                        <br />
                        <strong>Client Ref.:</strong> {clientReference || 'N/A'}
                        <br />
                        <strong>Sales Order:</strong> {salesOrder || 'N/A'}
                    </div>
                ),
            },
            {
                Header: 'Name',
                accessor: 'filename',
                show: false
            },
            {
                Header: 'Date',
                accessor: 'createdAt',
                show: false
            },
            {
                Header: 'Description',
                accessor: 'description',
                show: false
            },
            {
                Header: 'Client Ref.',
                accessor: 'clientReference',
                show: false
            },
            {
                Header: 'Sales Order',
                accessor: 'salesOrder',
                show: false
            },
            {
                Header: 'Search',
                accessor: 'search',
                show: false
            }
        ],
        []
    )
    if (isSuperAdmin === 1) {
        columns.push({ Header: 'Actions', show: false })
    }


    return (<Table columns={columns} data={rows} clickRow={clickRow} deleteItems={deleteItems} />);
}

export default FilesSmallScreen;
import { useState } from 'react';
import { CContainer, CRow, CCol, CCard, CCardBody, CBreadcrumb, CBreadcrumbItem } from '@coreui/react';
import Gravatar from 'react-gravatar';
import ProfileInfo from './ProfileInfo';
import ProfileInfoEdit from './ProfileInfoEdit';
import ProfilePasswordEdit from './ProfilePasswordEdit';

const MyProfile = ({
    userProfile,
    getUserProfile
}) => {

    const [editMode, setEditMode] = useState(false);
    const [editPasswordMode, setEditPasswordMode] = useState(false);
    const { email } = userProfile;

    const resetProfile = () => {
        getUserProfile();
        setEditMode(false);
        setEditPasswordMode(false);
    }

    const propsToPass = { userProfile, editMode, setEditMode, editPasswordMode, setEditPasswordMode, resetProfile };

    return (
        <CContainer>
            <CBreadcrumb className="text-center mt-4 mb-4">
                <CBreadcrumbItem active>My Profile</CBreadcrumbItem>
            </CBreadcrumb>
            <CRow className="justify-content-center">
                <CCol xs="12" sm="10" md="7" lg="6" xl="5" xxl="4">
                    <CCard className='mb-3'>
                        <Gravatar email={email || 'example@gmail.com'} size={120} className="rounded-circle mx-auto mt-4" />
                        <CCardBody>
                            <ProfileInfo {...propsToPass} />
                            <ProfileInfoEdit {...propsToPass} />
                            <ProfilePasswordEdit {...propsToPass} />
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    );
}

export default MyProfile;
const Forbidden = () => {

    return (
        <div className="container">
            <main className="p-5 mx-auto mb-3 mt-3 border bg-white" style={{ maxWidth: '400px' }}>
                <h1>Forbidden</h1>
                <p>You are not authorized to access this page.</p>
                <button className="btn btn-primary" onClick={() => window.location.href = '/'}>
                    Go to Home
                </button>
            </main>
        </div>
    );
}
export default Forbidden;

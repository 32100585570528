import API from "./api";

const getUserAuthorizedFolders = (userId) =>
    API.get(`permission-users/folder/with-permission/${userId}`)
        .then((response) => response.data);

const getUserUnauthorizedFolders = (userId) =>
    API.get(`permission-users/folder/without-permission/${userId}`)
        .then((response) => response.data);

const setUserAuthorizedFolders = (userId, folders) =>
    API.post(`permission-users/${userId}`, { folders })
        .then((response) => response.data);

const PermissionService = {
    getUserAuthorizedFolders,
    getUserUnauthorizedFolders,
    setUserAuthorizedFolders
}

export default PermissionService; 
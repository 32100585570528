import { CContainer, CHeader, CHeaderBrand, CHeaderNav, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from "@coreui/react"
import { navItens, userNavItens } from "./HeaderNavItens";
import Logo from "./Logo";
import HeaderLargeScreenNavIten from "./HeaderLargeScreenNavIten";

const HeaderLargeScreen = ({ userProfile }) => {
    const username = userProfile ? userProfile.username : '';
    const isSuperAdmin = userProfile ? userProfile.isSuperAdmin : false;

    return (
        <CHeader>
            <CContainer>
                <CHeaderBrand href="/">
                    <Logo height="50" />
                </CHeaderBrand>
                <CHeaderNav>
                    {navItens.map(item => {
                        delete item.icon;
                        const { hide: isToHide = false } = item;
                        const showAdmin = !isToHide && item.onlySuperAdmin && isSuperAdmin && isSuperAdmin === 1 ? true : false;
                        const showAllUsers = !isToHide && !item.onlySuperAdmin;
                        return (
                            <span key={item.name}>
                                {showAdmin && (<HeaderLargeScreenNavIten {...item} />)}
                                {showAllUsers && (<HeaderLargeScreenNavIten {...item} />)}
                            </span>
                        )
                    })}
                    <CDropdown variant="nav-item">
                        <CDropdownToggle color="secondary">{username}</CDropdownToggle>
                        <CDropdownMenu>
                            {userNavItens
                                .map(({ name, url, onClick }) => (
                                    <CDropdownItem href={url} onClick={onClick} key={name}>
                                        {name}
                                    </CDropdownItem>
                                ))
                            }
                        </CDropdownMenu>
                    </CDropdown>
                </CHeaderNav>
            </CContainer>
        </CHeader>
    )
}

export default HeaderLargeScreen;
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignIn from './pages/SignIn';
import Default from './pages/Default';
import Certificates from './components/Certificates';
import Files from './components/Files';
import Recycle from './components/Recycle';
import Users from './components/Users';
import Help from './components/Help';
import MyProfile from './components/MyProfile';
import UserProfile from './components/UserProfile';
import ResetPassword from './pages/ResetPassword';
import AddUser from './components/AddUser'
import Forbidden from './pages/Forbidden';
import { useEffect } from 'react';
import { setProperties } from './helper/css';

const routes = [
  { path: "/signin", element: <SignIn /> },
  { path: "/reset/token=:token", element: <ResetPassword /> },
  { path: "/", element: <Default children={<Certificates />} /> },
  { path: "/files", element: <Default children={<Files />} /> },
  { path: "/certificates", exact: true, element: <Default children={<Certificates />} /> },
  { path: "/certificates/files", element: <Default children={<Files />} /> },
  { path: "/recycle", element: <Default children={<Recycle />} /> },
  { path: "/users", element: <Default children={<Users />} /> },
  { path: "/users/add", element: <Default children={<AddUser />} /> },
  { path: "/users/profile", element: <Default children={<UserProfile />} /> },
  { path: "/help", element: <Default children={<Help />} /> },
  { path: "/profile", element: <Default children={<MyProfile />} /> },
  { path: "/forbidden", element: <Forbidden /> }
];

const App = () => {
  useEffect(setProperties, []);

  return (
    <Router>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
      </ Routes>
    </Router>
  );
}

export default App;

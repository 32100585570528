import { useEffect, useState, cloneElement } from 'react';
import { ToastContainer } from 'react-toastify';
import UserService from '../services/user.service';
import Header from './Header';

const Layout = ({ children }) => {
    const [userProfile, setUserProfile] = useState(null);

    useEffect(() => { getUserProfile() }, []); 

    const getUserProfile = () => UserService.getUserProfile()
        .then(userProfile => setUserProfile(userProfile))

    if (!userProfile) {
        return (<></>);
    }

    const propsToPass = { userProfile, getUserProfile };

    return (
        <div>
            <Header {...propsToPass} />
            <ToastContainer />
            {cloneElement(children, { ...propsToPass, key: JSON.stringify(userProfile) })}
        </div>
    );
}

export default Layout;
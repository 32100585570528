import { useMemo } from "react";
import Table from "./Table/Table";
import UserRole from "./UserRole";
import UserStatus from "./UserStatus";

const UsersSmallScreen = ({ users, clickRow }) => {

    const columns = useMemo(
        () => [
            {
                Header: 'User Information',
                Cell: ({ cell: { row: { original: { name, username, isSuperAdmin, active, email, companyName, companyCode } } } }) => (
                    <div>
                        <strong>Name:</strong> {name}
                        <br />
                        <strong>Username:</strong> {username}
                        <br />
                        <strong>Role:</strong>
                        <UserRole isSuperAdmin={isSuperAdmin} />
                        <br />
                        <strong>Status:</strong>
                        <UserStatus active={active} />
                        <br />
                        <strong>Email:</strong> {email}
                        <br />
                        <strong>Company:</strong> {companyName}
                        <br />
                        <strong>Company Code:</strong> {companyCode}
                    </div>
                ),
            },
            {
                Header: 'Username',
                accessor: 'username',
                show: false,
            },
            {
                Header: 'Email',
                accessor: 'email',
                show: false,
            },
            {
                Header: 'Company Name',
                accessor: 'companyName',
                show: false,
            },
            {
                Header: 'Company Code',
                accessor: 'companyCode',
                show: false,
            },
            {
                Header: 'Role Label',
                accessor: 'isSuperAdminLabel',
                show: false,
            },
            {
                Header: 'Status Label',
                accessor: 'activeLabel',
                show: false,
            },
        ],
        []
    );

    return (<Table columns={columns} data={users} clickRow={clickRow} />);
}

export default UsersSmallScreen;
import { useMemo } from "react";
import Table from "./Table/Table";
import DefaultCell from "./Table/DefaultCell";


const FilesLargeScreen = ({ data: { rows, count }, clickRow, isSuperAdmin, deleteItems }) => {

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'filename',
                Cell: ({ value }) => (<DefaultCell value={value} />),
            },
            {
                Header: 'Date',
                accessor: 'createdAt',
                Cell: ({ value }) => (<DefaultCell value={value} />),
            },
            {
                Header: 'Description',
                accessor: 'description',
                Cell: ({ value }) => (<DefaultCell value={value} />),
            },
            {
                Header: 'Client Ref.',
                accessor: 'clientReference',
                Cell: ({ value }) => (<DefaultCell value={value} />),
            },
            {
                Header: 'Sales Order',
                accessor: 'salesOrder',
                Cell: ({ value }) => (<DefaultCell value={value} />),
            },
            {
                Header: 'Search',
                accessor: 'search',
                show: false
            }
        ],
        []
    )
    if (isSuperAdmin === 1) {
        columns.push({
            Header: 'Actions',
            show: false,
            Cell: ({ value }) => (<DefaultCell value={'delete'} />),
        })
    }
    return (<Table columns={columns} data={rows} clickRow={clickRow} deleteItems={deleteItems} />);
}

export default FilesLargeScreen;
import moment from 'moment';
import Folder from '../Folder';

const DefaultCell = ({
    icon,
    value
}) => {
    const isMoment = value &&
        !value.includes(" ") &&
        !value.includes("_") &&
        value.includes("Z") &&
        moment(value).isValid();

    return (
        <div>
            {isMoment && moment(value).format('DD/MM/YYYY')}
            {!isMoment && !icon && value}
            {!isMoment && icon === 'folder' && (<Folder name={value} />)}
        </div>
    );
}

export default DefaultCell;
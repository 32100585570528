import API from "./api";
import AuthService from './auth.service.js';

const getUserProfile = (userId = false) =>
    API.get(`user${userId ? `/${userId}` : ''}/profile`)
        .then((response) => response.data);

const updateUserProfile = (user, userId = false) =>
    API.patch(`user${userId ? `/${userId}` : ''}/profile`, user)
        .then((response) => response.data)
        .catch((error) => {
            if (error.response.status === 304) {
                return Promise.resolve();
            }
            return Promise.reject(error);
        });

const createUser = (user) =>
    API.post('user', user)
        .then((response) => response.data);

const updatePassword = (currentPassword, newPassword, userId = false) =>
   API.patch(`user${userId ? `/${userId}` : ''}/password`,
        { currentPassword, newPassword })
        .then(() => { if (!userId) { AuthService.logout() } }); 
        
const deleteUser = (userId = false) =>
  API.delete(`user${userId ? `/${userId}` : ""}`,).then(() => {
    if (!userId) {
      AuthService.logout();
    }
  }); 

const requestResetPassword = (email, username) =>
    API.post('reset-password/request-new-password', { email, username });

const resetPassword = (token, password, confirmPassword) =>
    API.patch('reset-password/reset', { token, password, confirmPassword });

const getAllUsers = () =>
    API.get('user/all')
        .then((response) => response.data)
        .then((users) => users.map((user) => {
            user.activeLabel = user.active === 1 ? "Active" : "Inactive";
            user.isSuperAdminLabel = user.isSuperAdmin === 1 ? "Super Admin" : "Normal User";
            return user;
        })); 

const UserService = {
    getUserProfile,
    updateUserProfile,
    createUser,
    updatePassword,
    requestResetPassword,
    resetPassword,
    getAllUsers,
    deleteUser,
}

export default UserService; 